<template>
  <div style="background-color: #f6f6f6;">
    <van-nav-bar
      title="精彩活动"
      left-arrow
      @click-left="goBack"
      fixed
      placeholder
    />

    <div class="mainDiv GeTop" style="border-radius: 5px 5px 0 0;">
      <van-row style="display: flex; align-items: center;">
        <van-col span="">
          <div class="GeLogo" style="width: 76px; display: flex; justify-content: center; align-items: center;">
            <van-image
            width="48"
            height="48"
            :src="data.taskInfo.logo"/>
          </div>
        </van-col>

        <van-col :span="7" style="flex: 1;">
          <span>
            <span class="spandiv">{{data.taskInfo.name}}</span>
          </span>
        </van-col>

        <van-col :span="6">
          <span style="  font-size: 18px;
            font-family: PingFangSC-Regular;
            color: rgba(255, 82, 0, 1);
            margin-right: 2px;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;" 
          >+{{ parseInt(data.taskInfo.award) }}</span>
          <span style=" font-size: 12px;
            font-family: PingFangSC-Regular;
            color: rgba(255, 82, 0, 1);
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;"
          >金币</span>
        </van-col>
      </van-row>
    
 
      <div class="day">
        <van-cell :border="false" style="color: rgba(153, 153, 153, 1);
          font-size: 12px;font-family: PingFangSC-Regular;"
        >
          <van-col :span="12">  <span>剩余份数：{{data.taskInfo.lessNum}}</span></van-col>
        </van-cell>
        <van-cell :border="false" style="margin-top:-10px">
          <van-col :span="8">
            <div style="display: flex; align-items: center;">
              <i class="iconfont icon-icon_duihao-mian" style="color: rgba(98, 209, 146, 1);
                margin-right: 5px;
                font-size: 14px;"></i>
              <span style="color: rgba(98, 209, 146, 1);
                font-size: 12px;"
              >无恶意收费</span>
            </div>
          </van-col>
          <van-col :span="8">
            <div style="display: flex; align-items: center;">
              <i class="iconfont icon-icon_duihao-mian" style="color: rgba(98, 209, 146, 1);
                margin-right: 5px;
                font-size: 14px;"></i>
              <span style="color: rgba(98, 209, 146, 1);
                font-size: 12px;"
              >人工检测无毒</span>
            </div>
          </van-col>
          <van-col :span="8">
            <div style="display: flex; align-items: center;">
              <i class="iconfont icon-icon_duihao-mian" style="color: rgba(98, 209, 146, 1);
                margin-right: 5px;
                font-size: 14px;"></i>
              <span style="color: rgba(98, 209, 146, 1);
                font-size: 12px;"
              >加密防泄漏</span>
            </div>
          </van-col>
        </van-cell>
      </div>
      <div class="btn">
        <van-goods-action-button @click="downLoad(link)" style="width: 54%; height: 49px; margin-left: 23%; font-size: 15px;" type="warning" text="下载APP" />
      </div>
    </div>
    

    <!--试玩步骤-->
    <div class="mainDiv img">
      <span class="info4">试玩步骤</span>
      <div style="margin-left:10px; margin-right:10px;" v-for="(item, index) in data.stepInfo" :key="index">
        <span class="info2">{{ item.title }}</span> 
        <span> 
          <div  v-if="item.pic.length==0?false:true">
            <button class="bd-div">
              <div style="display: flex; justify-content: center; align-items: center;">
                <span>点击可查看大图</span>
              </div>
            </button>
          </div>
          <div style="margin-bottom: 12px;">
            <van-image @click="getImg(item.pic, index)"
              style="margin-right: 8px;"
              v-for="(img, index) in item.pic"
              :key="index"
              radius="9"
              width="80"
              height="80"
              fit="fill"
              :src="img">
              <template v-slot:error></template>
            </van-image>
          </div>
        </span>
      </div>
    </div>
    <!--试玩步骤-->

    <div class="mainDiv Gebottom">
      <div style="margin-left:10px;margin-top:10px; padding-bottom: 100px;">
        <div>
          <span class="info3">试玩步骤</span>
          <span>
            <button class="bd-div1">
              <div style="display: flex; justify-content: space-evenly; align-items: center;">
                <i class="iconfont icon-anquanbaozhang"></i>
                <span style="margin-top: ;">安全保障</span>
                <van-icon name="arrow" />
              </div>
            </button>
          </span>
        </div>
        <span style="margin-left:10px;margin-top:5px">
          <span style="display:block; margin-bottom: 5px;">提交信息</span>
          <span style="margin-top:10px" class="GeInfo">
            <van-form ref="form">
              <div style="margin-bottom: 10px;" v-if="data.taskInfo.sub_option.includes('mobile')?true:false">
                <van-cell-group>
                  <van-field 
                    v-model="mobile"
                    placeholder="请输入手机号"
                    :rules="[
                      { required: true, message: '' },
                      { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
                    ]"
                  />
                </van-cell-group>
              </div>
              <div style="margin-bottom: 10px;" v-if="data.taskInfo.sub_option.includes('userName')?true:false">
                <van-cell-group>
                  <van-field 
                    v-model="userName"
                    placeholder="请输入用户名" 
                    :rules="[
                      { required: true, message: '' }
                    ]"
                  />
                </van-cell-group>
              </div>
              <div style="margin-bottom: 10px;" v-if="data.taskInfo.sub_option.includes('realName')?true:false">
                <van-cell-group>
                  <van-field 
                    v-model="realName"
                    placeholder="请输入用户姓名" 
                    :rules="[
                      { required: true, message: '' }
                    ]"
                  />
                </van-cell-group>
              </div>
              <div style="margin-bottom: 10px;" v-if="data.taskInfo.sub_option.includes('others')?true:false">
                <van-cell-group>
                  <van-field 
                    v-model="others"
                    placeholder="请输入选项内容" 
                    :rules="[
                      { required: true, message: '' }
                    ]"
                  />
                </van-cell-group>
              </div>
            </van-form>
          </span>
        </span>
        <span style="margin-left:10px;margin-top:10px">
          <span style="display:block; margin-bottom: 11px;">上传截图</span>
          <span style="margin-top:10px">
            <van-uploader 
              v-model="fileList"
              :after-read="afterRead(fileList)"
              :before-delete="deletePics"
              max-count="3"
            />
          </span> 
        </span>
      </div>
    </div>
<!---提交--->
    <div class="GeCommit">
      <van-goods-action>
        <van-goods-action-button type="warning" text="提交信息" @click="commit" />
      </van-goods-action>
    </div>
  </div>
</template>

<script>
import { ImagePreview} from "vant";
import { fetchPictrueList, upload, commitTask, getTask } from '@/api/gameDomes'
export default {
  name: "makerGameDetail",
  data() {
    return {
      pics: [],
      link: '', // 下载链接
      images:[],
      fileList: [],
      mobile: '', // 手机号码
      userName: '', // 用户名
      realName: '', // 用户姓名
      others: '', // 选项内容
      id: '5179',
      device: 'IMEI',
      phone: null,
      data: {},
    }
  },
  created() {
      /**
   * 媒体方用户设备类型。Android - 1；iOS - 2
   */
      /**
   * 媒体方用户设备。iOS - IDFA；Android - IMEI（卡槽1）
   * (安卓10以上系统使用oaid)oaid的获取方式可参考：http://www.msa-alliance.cn/col.jsp?id=120
   */
    this.$store.commit('setSession', this.$route.query.session)
    this.getDetail()
    this.getPhoneType()
    if (this.$route.query.id) {
      this.id = this.$route.query.id,
      this.device = this.$route.query.device
    }
    console.log(this.$route, '路由')
  },
  methods: {
    getPhoneType () {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        this.phone=2
        this.getTask()
        this.fetchPictrueList()
      }
      if (isAndroid) {
        this.phone=1
        this.getTask()
        this.fetchPictrueList()
      }
    },

    // 领取
    getTask () {
      getTask({
        id: this.$route.query.id,
        phone: this.phone
      }).then(res => {
        if (res.data.code ===0) {
          // this.$toast(res.data.msg)
        } else {
          this.$toast(res.data.msg)
        }
      })
    },

    goBack () {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isIOS) {
        window.webkit.messageHandlers.close.postMessage('up');   
      }
      if (isAndroid) {
          window.android.close(); 
      } 
    },

    getDetail () {
      let ispeed = Math.floor(-this.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
      this.scrollTop + ispeed;
    },

    getImg(images, index) { // images是数组
      console.log(images, '图片')
      ImagePreview({
        images: images,
        showIndex: true,
        loop: false,
        startPosition: index,
      });
    },

    // 请求截图
    fetchPictrueList () {
      let params = {
        id:this.$route.query.id, 
        device: this.$route.query.idfa ? this.$route.query.idfa : this.$route.query.deviceId,
        phone: this.phone, 
        device2: this.$route.query.device2
      }
      fetchPictrueList(params).then(res => {
        console.log(res, '截图详情')
        if (res.data.code === 0) {
          this.data = res.data.data
          if (res.data.data.taskInfo.task_type == '1') {
            this.link = res.data.data.taskInfo.link
          } else {
            this.link = res.data.data.taskInfo.qr_code
          }
          console.log(this.link)
        }
      })
    },

    // 下载
    downLoad (link) {
      console.log(link, 'link')
      try{
        window.webkit.messageHandlers.openBrowser.postMessage(link)
      }catch{ 
        window.android.openBrowser(link)
      }
    },

    commit () {
      this.$refs.form.validate().then(() => {
        if (!this.pics.length) {
          this.$toast.fail('请上传截图')
          return
        }
        let params = {
          device: this.$route.query.device,
          device2: this.$route.query.device2,
          phone: this.phone,
          id: this.$route.query.id,
          pics: JSON.stringify(this.pics),
          picsCount: this.pics.length,
          mobile: this.mobile,
          userName: this.userName,
          realName: this.realName,
          others: this.others,
        }
        console.log(params, 'params')
        commitTask(params).then(res => {
          console.log(res, '提交任务')
          if (res.data.code === 0) {
            this.$toast(res.data.msg)
            this.goBack()
          } else {
            this.$toast(res.data.msg)
          }
        })
      }).catch(() => {
        this.$toast.fail('请填写提交信息')
      })
    },

    afterRead () {
      return (file) => {
        file.status = 'uploading'
        file.message = '上传中'
        let formData = new window.FormData()
        formData.append('file', file.file)
        formData.append('fileType', 'imgage/png'),
        formData.append('dir', 'material')
        upload(formData).then(res => {
          console.log(res, '5435345345')
          if (res.data.code === 0) {
            file.status = ''
            file.message = ''
            this.pics.push(res.data.data)
          }
        }).catch(() => {
          file.status = 'failed',
          file.message = '上传失败'
        })
      }
    },

    deletePics (file, detail) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm({
          title: '截图',
          message: '确认删除截图？'
        }).then(() => {
          this.pics.splice(detail.index, 1)
          this.$toast.success('删除成功')
          resolve()
        }).catch(() => {
          this.$toast.fail('已取消')
          reject()
        })
      })
    }
  }
};
</script>

<style lang="less" scoped>
.mainDiv{
    margin-top: 10px;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 20%);
    align-self: center;
    justify-content: center;
    width: 96%;
}
.img {
  padding: 10px 0px 0;
  box-sizing: border-box;
}
.Gebottom {
  padding-top: 10px;
  
}
.GeTop {
  padding-top: 10px;
}
.btn {
  background: #fff;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  padding-bottom: 10px;
  border-radius: 0 0 5px 5px;
}
.spandiv {
  width: 64px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  text-align: left;
}
.spandiv2 {
  width: 43px;
  height: 17px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 12px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  text-align: left;
  align-self: flex-start;

}

.bd-div {
  height: 19px; 
  width: 100px;
  background-color: rgba(238, 238, 238, 1); 
  color: rgba(153, 153, 153, 1);
  font-size: 12px; 
  padding: 0;
  border-radius: 11.5px 11.5px 11.5px 11.5px;
  margin: 11px 0;
}
.bd-div1 {
  width: 100px;
  background: linear-gradient(to right,#2aecac,#08ebd8);
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  height: 24px;
  padding: 0;
  border-radius: 11.5px 11.5px 11.5px 11.5px;
  
}
.txt {
  width: 277px;
  height: 17px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 12px;
  letter-spacing: -0.02500000037252903px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  text-align: left;
  align-self: flex-start;
  margin-top: 10px;
}
.txt2 {
  width: 277px;
  height: 17px;
  display: block;
  overflow-wrap: break-word;
   color: rgba(153, 153, 153, 1);
  font-size: 12px;
  letter-spacing: -0.02500000037252903px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  text-align: left;
  align-self: flex-start;
  margin-top: 5px;
}
.info{
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  display: block;
  margin-top:10px;
}
.info2{
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  display: block;
  margin-top:10px;
}
.info3{
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}
.info4 {
  font-size: 18px;
  margin-top:10px;
  font-weight: 600;
  margin-left: 10px;
}
.van-goods-action-button--warning {
    background: linear-gradient(to right,#f4460f,#ff921e);
}
.van-field__control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    color: #323233;
    line-height: inherit;
    text-align: left;
    background-color: #efefef;
    border: 2px;
    resize: none;
    height: 40px;
}
.van-count-down {
    color: #323233;
    font-size: 14px;
    line-height: 20px;
    margin-top: -20px;
    margin-left: 50px;
        color: #ff5200;
         font-size: 12px;
  font-family: PingFangSC-Semibold;
}
.day {
  img {
    vertical-align: middle;
    margin-right: 5px;
  }
}
.GeLogo {
  /deep/ .van-image__img {
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(190, 190, 190, .5);
  }
}
.GeInfo {
  .van-cell-group {
    width: 60%;
    .van-cell {
      background: #eee;
      border-radius: 6px;
      padding: 8px 16px;
    }
  }
}
/deep/ .GeCommit .van-goods-action {
  height: 50px;
}
</style>