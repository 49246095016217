import { fetchGet, fetchPost } from '@/router/https'

// 请求截图列表
const fetchPictrueList = (params) => fetchGet('/createrapp/screenshot/task/detail', params)

const upload = (param) => fetchPost('/mallapp/feedbackrelease/uploadFile', param)

const commitTask = (params) => fetchGet('/createrapp/screenshot/task/verify', params)
const getTask = (params) => fetchGet('/createrapp/screenshot/task/apply', params)

export {
  fetchPictrueList,
  upload,
  commitTask,
  getTask
}